<template>
  <ClientOnly>
    <div
      class="fixed inset-0 flex items-center justify-center w-full z-[99999999999]"
    >
      <div @click="closeModal" class="fixed inset-0 pdv-overlay-bg"></div>
      <span @click="closeModal" class="absolute right-5 top-5 z-[9999999]">
        <CloseIcon class="block w-6 h-6 text-white cursor-pointer" />
      </span>

      <div class="w-[95dvw] h-[85dvh] md:w-[85dvw] z-10">
        <div
          class="relative flex items-center justify-center w-full h-full"
          v-if="isLoading"
        >
          <Spinner
            class="absolute top-[48%] left-[48%] z-10 text-white animate-spin w-14 h-14"
          />
          <img
            class="w-auto h-auto"
            v-if="video.youtube_details.maxres"
            :src="video.youtube_details.maxres.url"
          />
        </div>
        <iframe
          v-show="!isLoading"
          :src="generateIframeSrc"
          frameborder="0"
          class="w-full h-full"
          allowfullscreen
          @load="onVideoLoaded"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;web-share"
        ></iframe>
      </div>
    </div>
  </ClientOnly>
</template>
<script setup>
import CloseIcon from "@/assets/svg/heroicons/close.svg?component";

const props = defineProps({
  video: {
    type: Object,
    required: true,
  },
});

const isLoading = ref(true);
const emit = defineEmits();

const closeModal = () => {
  emit("close");
};
const onVideoLoaded = () => {
  isLoading.value = false;
};

const generateIframeSrc = computed(() => {
  const settings = props.video.settings;

  const videoId = props.video.youtube_details.id;
  let src = `https://www.youtube.com/embed/${videoId}?autoplay=1&`;

  const params = [];

  // Add query parameters based on video settings
  params.push(`controls=${settings.video_controls ? 1 : 0}`);
  params.push(`fs=${settings.video_fs ? 1 : 0}`);
  params.push(`cc_load_policy=${settings.video_cc_policy ? 1 : 0}`);
  params.push(`rel=${settings.video_rel ? 1 : 0}`);

  // Join the parameters and append them to the URL
  src += params.join("&");

  return src;
});
</script>
<style scoped>
.pdv-overlay-bg {
  background: rgba(33, 33, 33, 0.8);
  backdrop-filter: blur(43px);
}
@media (max-width: 767px) {
  .pdv-overlay-bg {
    @apply bg-black filter-none;
  }
}
</style>