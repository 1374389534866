export const usePodcastStore = defineStore("podcast", {
    state: () => ({
        isInitialLoading: true,
        hasLoadMore: false,
        isLoading: false,
        isProductPodcastLoading: false,
        perPage: 15,
        videos: [],
        productVideos: [],
        meta: {
            current_page: 1,
        },
    }),

    actions: {
        fetchPodacasts(params, initial) {
            const nuxtApp = useNuxtApp();

            if (this.isLoading) return false

            if (initial) {
                this.videos = []
                this.meta.current_page = 1
                this.isInitialLoading = true
            } else if (this.hasLoadMore) {
                this.meta.current_page = this.meta.current_page + 1
                this.isLoading = true;
            }

            const queryParams = nuxtApp.$convertQueryParams(params)

            nuxtApp.$api(`/api/front/podcast?${queryParams}&page=${this.meta.current_page}&per_page=${this.perPage}`)
                .then(({ data, meta }) => {
                    this.meta = meta
                    this.videos = [...this.videos, ...data]

                    this.isInitialLoading = false
                    this.isLoading = false;
                    if (this.meta.current_page < this.meta.last_page) {
                        this.hasLoadMore = true
                    } else {
                        this.hasLoadMore = false
                    }
                })
                .catch((e) => {
                    this.isInitialLoading = false
                    this.isLoading = false;

                    console.error("[podcast] load error", e);
                });
        },
        fetchProductPodacasts(id) {
            this.isProductPodcastLoading = true;

            useAPI(`/api/front/podcast/${id}/Product?with[]=tags`)
                .then(({ data }) => {

                    this.productVideos = data._rawValue?.data || []
                    this.isProductPodcastLoading = false
                })
                .catch((e) => {
                    this.isProductPodcastLoading = false

                    console.error("[podcast] load error", e);
                });
        },

    },

});